































import axios from 'axios';
import IPInfoContent from './IPPopup/IPInfoContent.vue';
import rService from '../services/RequestService/RequestServiceSetup';
export default {
  components: {
    IPInfoContent,
  },
  data() {
    return {
      valid_ip: false,
      sel_ip: '',
      ip: '',
      rules: {
        required: (value: string) => !!value || 'Required.',
        validate_ip: (str: string) => {
          const regexExp = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;
          return regexExp.test(str) || 'The ip is not valid.'; // true
        },
      },
    };
  },
  methods: {
    checkExperimentalG() {
      return {
        name: 'experimentalCheck',
        params: {
          type: 'lastip',
          entry: this.ip,
        },
      };
    },
    checkDehashedG() {
      return {
        name: 'dehashedCheck',
        params: {
          type: 'ip_address',
          entry: this.ip,
        },
      };
    },
    translate(text: string) {
      return this.$vuetify.lang.t('$vuetify.Home.' + text);
    },
    clear() {
      this.sel_ip = '';
    },
    async use_my_ip() {
      const endPoint = 'https://api.ipify.org?format=json';
      const res = await axios.get(endPoint).then((res) => res.data);
      this.ip = res.ip;
    },
    check_ip() {
      this.sel_ip = this.ip;
    },
  },
};
