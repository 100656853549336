















































import hashing from '@/services/Hashing';
import IpInfo from '@/components/IpInfo.vue';
export default {
  components: {
    IpInfo,
  },
  data() {
    return {
      hash_types: JSON.parse(JSON.stringify(hashing.hash_types)),
      hash_type: 'md5',
      lines: [],
      toHash: '',
      hash_applied: false,
      hashedText: '',
      new_lines: [],
    };
  },
  methods: {
    getLink(name: string, entry: string, type: string) {
      return {
        name: name,
        params: {
          id: 0,
          entry: entry,
          type: type,
        },
      };
    },
    translate(item) {
      return this.$vuetify.lang.t('$vuetify.Home.' + item);
    },
    applyHash() {
      this.lines = this.toHash
        .split('\n')
        .map((el: string) => el.trim())
        .filter((el: string) => el);
      this.new_lines = hashing.applyHashMulti(this);
      this.hashedText = this.new_lines.join('\n');
    },
  },
};
